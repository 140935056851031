import { library, config } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { faBadgeCheck } from '@fortawesome/pro-light-svg-icons';
import {
	faBars,
	faCalendarDays,
	faTimes,
	faCircleCheck,
	faPhone,
	faEnvelope,
	faChevronUp,
	faUserGroup,
	faArrowsMaximize,
} from '@fortawesome/pro-regular-svg-icons';
import { faFacebook, faInstagram, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faStar, faStarHalf } from '@fortawesome/pro-solid-svg-icons';

// This is important, we are going to let Nuxt.js worry about the CSS
config.autoAddCss = false;

library.add(
	faCalendarDays,
	faUserGroup,
	faArrowsMaximize,
	faChevronUp,
	faBadgeCheck,
	faPhone,
	faEnvelope,
	faBars,
	faTimes,
	faCircleCheck,
	faFacebook,
	faTwitter,
	faInstagram,
	faLinkedin,
	faStar,
	faStarHalf,
);

export default defineNuxtPlugin(({ vueApp }) => {
	vueApp.component('font-awesome-icon', FontAwesomeIcon, {});
});
